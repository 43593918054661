/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormatPrice from '../../../helpers/FormatPrice';
import Button from '../../common/Button';
import styles from '../Steps/style.module.scss';
import shippingMethodStyles from './style.module.scss';
import setDataLayer from '../../../helpers/DataLayer';

import { MagentoData } from '../../../services/MagentoApi';

import { getDeliveryDateHours, getDeliveryDateTime } from '../../../helpers/MageStorage';

const ShippingMethods = (props) => {
  const {
    shippingMethods,
    shippingMethod,
    shippingAddress,
    billingAddress,
    isValid,
    isEditing,
    currentStep,
    steps,
    setShippingMethod,
    setShippingInformation,
    isExpress,
    paymentMethod,
    emailAddress,
    expressPaymentData,
    setLoading,
    setCheckoutSuccess,
    orderDateTime,
    cartData,
  } = props;

  const shippingInformation = {
    shippingAddress,
    billingAddress,
  };

  const {
    methodTime: stateMethodTime = '',
    methodTimes: stateMethodTimes = [],
  } = shippingMethod;

  const [currentMethod, setCurrentMethod] = useState();
  const [methodTime, setMethodTime] = useState();
  const [error, setError] = useState(null);
  const [notimeserror, setNotimeserror] = useState(false);
  const [editingTime, setEditingTime] = useState(false);
  
  const { order } = steps;
  const thisStepIndex = order.findIndex((step) => step.name === 'shippingMethods');
  const isReady = (currentStep === thisStepIndex);


  useEffect(() => {
    if (methodTime || currentMethod) {
      const method = currentMethod || shippingMethod;

      const updateShippingMethod = async () => {
        await setShippingMethod(method, methodTime);

        shippingInformation.methodCode = method.method_code;
        shippingInformation.carrierCode = method.carrier_code;

        if (isExpress) {
          await setShippingInformation(shippingInformation, currentStep, !isExpress);
        }
      };
      updateShippingMethod();
    }

  }, [
    currentMethod,
    methodTime,
  ]);


  useEffect(() => {
    if(isReady){
      const method = currentMethod || shippingMethod;
      setDataLayer( cartData, currentStep, method.method_title );
    }
  }, [currentStep, currentMethod]);

  const updateMethod = async (method) => {
    setNotimeserror(false);
    const hours = getDeliveryDateHours(method);

    if (hours === false) {
      setNotimeserror(true);
      return;
    }

    setMethodTime(hours[0]);
    setEditingTime(true);
    setCurrentMethod(method);
  };

  const handleTimeChange = (e) => {
    setMethodTime(e.target.value);
    setEditingTime(false);
  };

  const proceedToPayment = () => {
    // standard delivery is not available as express payment
    // so we only pass the date/time with normal checkout flow

    shippingInformation.methodCode = shippingMethod.method_code;
    shippingInformation.carrierCode = shippingMethod.carrier_code;

    setShippingInformation(shippingInformation, currentStep, !isExpress, false);
  };

  const placeOrder = async () => {
    setLoading(true);

    const email = emailAddress;
    const { paymentData = {} } = expressPaymentData;
    const paymentResponse = MagentoData.submitPayment(
      { payment_method_nonce: paymentData.payment_method_nonce },
      email,
      billingAddress,
      paymentMethod,
    );

    paymentResponse.then((response) => {
      if (response.status === 200) {
        setCheckoutSuccess();
      } else {
        setError(true);
      }
    });
  };

  const methodSelected = (method) => (method.method_code === shippingMethod.method_code);

  const changeTime = () => {
    setNotimeserror(false);
    const hours = getDeliveryDateHours(shippingMethod);

    if (hours === false) {
      setNotimeserror(true);
      return;
    }

    setMethodTime(hours[0]);
    setEditingTime(true);
  };

  const FormattedDateTime = () => {
    let formattedDate;
    let formattedTime;

    if (orderDateTime) {
      formattedDate = new Date(orderDateTime);
      formattedTime = stateMethodTime || `${formattedDate.getHours() % 12 || 12}:${(`0${formattedDate.getMinutes()}`).slice(-2)}`;

      formattedDate = new Intl.DateTimeFormat('en-GB').format(formattedDate);

      return (
        <>
          {`Your order will be delivered: ${formattedDate}, ${formattedTime}`}
          <div className={styles.step__edit}>
            {(
              isValid
              && stateMethodTimes.length > 1
              && !editingTime) && (<Button label="change time" trigger={() => { changeTime(); }} buttonStyle="link" type="button" />)}
          </div>
        </>
      );
    }

    return (<></>);
  };

  const shippingSet = () => (isValid && shippingMethod && orderDateTime);
  const canEditTime = () => (
    shippingMethods.length > 0
    && stateMethodTimes.length > 1 && (editingTime || !stateMethodTime));

  return (
    <div className={styles.step__inner}>
      <div className={styles.step__edit}>
        {shippingMethods.length > 0 && (
          <ul className={shippingMethodStyles.shippingMethods}>
            {shippingMethods.map((method) => (
              <li key={method.carrier_code} className={shippingMethodStyles.shippingMethodsMethod}>
                <label>
                  <input
                    type="radio"
                    name="shippingMethod"
                    checked={methodSelected(method)}
                    onChange={() => { updateMethod(method); }}
                  />
                  <span>
                    {method.method_title}
                    {' '}
                    -
                    {' '}
                    {method.carrier_title}
                    <span>
                      {FormatPrice(method.base_amount)}
                    </span>
                  </span>
                </label>
              </li>
            ))}
          </ul>
        )}

        {shippingMethods.length < 1 && (
          <p>Sorry, there are no available delivery methods at this time.</p>
        )}

        {(notimeserror) && (
        <p>
          Sorry, there are no available times for this method.
          Please try a different method or amend your cart.
        </p>
        )}

        {(canEditTime()) && (
          <div className={shippingMethodStyles.orderDateTime}>
            Choose the time of your delivery:
            <select onChange={handleTimeChange}>
              {stateMethodTimes.map((time) => (
                <option value={time}>{time}</option>
              ))}
            </select>
          </div>
        )}
      </div>

      {(shippingSet()) && (
        <div className={shippingMethodStyles.shippingMethod}>
          <h5>
            {shippingMethod.method_title}
            {' '}
            -
            {' '}
            {shippingMethod.carrier_title}
            <span>
              {FormatPrice(shippingMethod.base_amount)}
            </span>
          </h5>
          <FormattedDateTime />
        </div>
      )}

      <div className={styles.step__nav}>
        {(!isExpress && !notimeserror) && (
          <button disabled={!shippingMethod.method_code} className="button-proceed" type="button" onClick={() => proceedToPayment()}>
            Proceed to Payment
          </button>
        )}
        {((shippingMethod.method_code) && isExpress) && (
          <>
            {(error) && (<div className="alert">Sorry, your payment was unsuccessful.</div>)}
            <button className="button-proceed" type="button" onClick={() => placeOrder()}>
              Place Order
            </button>
          </>
        )}
      </div>
    </div>
  );
};

ShippingMethods.propTypes = {
  shippingMethods: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  isValid: PropTypes.bool,
  isEditing: PropTypes.bool,
  shippingMethod: PropTypes.shape({
    method_code: PropTypes.string,
    carrier_code: PropTypes.string,
    method_title: PropTypes.string,
    carrier_title: PropTypes.string,
    base_amount: PropTypes.number,
    methodTimes: PropTypes.arrayOf(PropTypes.string),
    methodTime: PropTypes.string,
  }),
  shippingAddress: PropTypes.shape({}),
  billingAddress: PropTypes.shape({}),
  setShippingMethod: PropTypes.func,
  setShippingInformation: PropTypes.func,
  currentStep: PropTypes.number,
  isExpress: PropTypes.bool,
  setLoading: PropTypes.func,
  setCheckoutSuccess: PropTypes.func,
  emailAddress: PropTypes.string,
  paymentMethod: PropTypes.shape({}),
  expressPaymentData: PropTypes.shape({
    paymentData: PropTypes.shape({}),
  }),
  orderDateTime: PropTypes.string,
  cartData: PropTypes.shape({
    cart_type: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

ShippingMethods.defaultProps = {
  shippingMethods: [],
  isValid: false,
  isEditing: false,
  shippingMethod: {},
  shippingAddress: {},
  billingAddress: {},
  setShippingMethod: () => {},
  setShippingInformation: () => {},
  currentStep: 0,
  isExpress: false,
  setLoading: () => {},
  setCheckoutSuccess: () => {},
  emailAddress: '',
  paymentMethod: {},
  expressPaymentData: {
    paymentData: {},
  },
  orderDateTime: '',
  cartData: {
    cart_type: '',
    items: [],
  },
};

export default ShippingMethods;

import { connect } from 'react-redux';
import {
  setShippingMethod,
  setShippingInformation,
  setLoading,
  setCheckoutSuccess,
} from '../../store/actions/checkout';
import ShippingMethods from '../../components/wrappers/ShippingMethods';

const mapStateToProps = (state) => ({
  shippingMethods: state.checkout.shippingMethods,
  shippingMethod: state.checkout.shippingMethod,
  shippingAddress: state.customer.form.shippingAddress,
  billingAddress: state.customer.form.billingAddress,
  isExpress: state.checkout.isExpress,
  paymentMethod: state.checkout.paymentMethod,
  emailAddress: state.customer.form.emailAddress,
  expressPaymentData: state.checkout.expressPaymentData,
  orderDateTime: state.checkout.orderDateTime,
  cartData: state.checkout.cart,
});

const mapDispatchToProps = (dispatch) => ({
  setShippingMethod: (method, orderDateTime) => dispatch(setShippingMethod(method, orderDateTime)),
  setShippingInformation: (
    shippingInformation,
    currentStep,
    proceed,
    isClickAndCollect,
  ) => dispatch(setShippingInformation(
    shippingInformation,
    currentStep,
    proceed,
    isClickAndCollect,
  )),
  setLoading: (loading) => dispatch(setLoading(loading)),
  setCheckoutSuccess: (checkoutSuccess) => dispatch(setCheckoutSuccess(checkoutSuccess)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShippingMethods);
